
import * as React from "react";

interface IStatTable {
    perfData: any;
}

const StatTable: React.FC<IStatTable> = (props) => {

    const renderRow = (label: string, data: { count: number, p50: number, p95: number }) => {

        const getColor = (m: number) => {
            if (m > 500) return "red";
            if (m > 100) return "yellow";
            return "white";
        }

        return (
            <tr key={label}>
                <td>{label}</td>
                <td style={{ color: getColor(data.p50) }}>{data.p50}ms</td>
                <td style={{ color: getColor(data.p95) }}>{data.p95}ms</td>
            </tr>
        );

    }

    const perfData = props.perfData;

    const stats = () => {

        const rows = Object.keys(perfData).map(key => {
            return renderRow(key, perfData[key]);
        });

        return (
            <div style={{ overflowX: "auto" }}>
                <table className="stat-table">
                    <thead>
                        <tr>
                            <th>metric</th>
                            <th>P50</th>
                            <th>P95</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>

        );

    }

    return perfData ? stats() : null;
}

export default StatTable;
